// Contents
//
// Navbar
// Navbar brand
// Navbar nav
// Navbar text
// Navbar divider
// Responsive navbar
// Navbar position
// Navbar themes


// Navbar
//
// Provide a static navbar from which we expand to create full-width, fixed, and
// other navbar variations.

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap; // allow us to do the line break for collapsing content
  align-items: center;
  justify-content: space-between; // space out brand from logo
  z-index: $zindex-sticky;
  pointer-events: none;

  margin: 0 $body-border-width-base;
  padding: ($navbar-padding-y / 2) + $body-border-width-lg 0 0;

  @include media-breakpoint-up(md) {
    margin: 0 $body-border-width-lg;
  }

  @include media-breakpoint-up(lg) {
    padding: ($navbar-padding-y / 1.5) + $body-border-width-lg 0 0;
  }

  @include media-breakpoint-up(xl) {
    padding: ($navbar-padding-y + $body-border-width-lg) 0 0;
  }

  // Because flex properties aren't inherited, we need to redeclare these first
  // few properities so that content nested within behave properly.
  >.container,
  >.container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
}


// Navbar brand
//
// Used for brand, project, or site names.

.navbar-brand {
  display: inline-block;
  padding-top: $logo-padding-top;
  margin-right: $navbar-padding-x;
  font-size: $navbar-brand-font-size;
  line-height: inherit;
  white-space: nowrap;
  pointer-events: auto;

  @include hover-focus {
    text-decoration: none;
  }
}


// Navbar nav
//
// Custom navbar navigation (doesn't require `.nav`, but does make use of `.nav-link`).

.navbar-nav {
  display: flex;
  flex-direction: column; // cannot use `inherit` to get the `.navbar`s value
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  pointer-events: auto;

  @include media-breakpoint-down(sm) {
    height: 100%;
  }

  .nav-item {
    position: relative;

    &.active {
      .nav-link::after {
        content: "";
        display: block;
        background: $body-color;
        position: absolute;
        height: 2px;

        left: 50%;
        transform: translateX(-50%);
        width: 3.5rem;
        margin: 0 auto;
        bottom: 0;

        @include media-breakpoint-up(md) {
          right: 0;
          left: auto;
          transform: none;
          width: 2.5rem;
          margin-right: calc(#{$spacer / 2} + #{$nav-link-letter-spacing});
          bottom: -2px;
        }

        @include media-breakpoint-up(lg) {
          margin-right: calc(#{$spacer} + #{$nav-link-letter-spacing});
        }

      }
    }

    .nav-link {
      display: block;
      text-transform: uppercase;
      font-family: $font-family-sans-serif-brand;
      font-weight: 600;
      letter-spacing: $nav-link-letter-spacing;
      padding: ($nav-link-padding-y * 2) $nav-link-padding-x;

      @include media-breakpoint-up(md) {
        padding: $nav-link-padding-y $nav-link-padding-x;
        font-size: $font-size-xs;
      }
    }

    @include media-breakpoint-up(md) {
      &:last-child .nav-link {
        padding-right: 0;
      }

      &:last-child .nav-link::after {
        margin-right: 0;
      }
    }
  }

  .dropdown-menu {
    position: static;
    float: none;
  }
}


// Navbar text
//
//

.navbar-text {
  display: inline-block;
  padding-top: $nav-link-padding-y;
  padding-bottom: $nav-link-padding-y;
}


// Responsive navbar
//
// Custom styles for responsive collapsing and toggling of navbar contents.
// Powered by the collapse Bootstrap JavaScript plugin.

// When collapsed, prevent the toggleable navbar contents from appearing in
// the default flexbox row orienation. Requires the use of `flex-wrap: wrap`
// on the `.navbar` parent.
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  // For always expanded or extra full navbars, ensure content aligns itself
  // properly vertically. Can be easily overridden with flex utilities.
  align-items: center;

  // opacity: 1;
  @include media-breakpoint-up(md) {
    opacity: 1;
  }

  // Mobile adjustments
  @include media-breakpoint-down(sm) {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    align-self: center;
    z-index: 1;
    background: $white;

    opacity: 0;
    transform: translateY(-100%);
    transition: opacity .2s ease-in-out, transform .2s ease-in-out .2s;

    &.show {
      opacity: 1;
      transform: translateY(0%);
      transition: opacity .2s ease-in-out, transform 0s ease-in-out;
    }
  }
}

// Button for toggling the navbar when in its collapsed state
.navbar-toggler {
  padding: $navbar-toggler-padding-y $navbar-toggler-padding-x;
  margin-right: -$navbar-toggler-padding-y;
  font-size: $navbar-toggler-font-size;
  line-height: 1;
  background: transparent; // remove default button style
  border: 0 solid transparent; // remove default button style
  pointer-events: auto;
  // @include border-radius($navbar-toggler-border-radius);

  @include hover-focus {
    text-decoration: none;
  }

  // Mobile adjustments
  position: relative;
  z-index: 2;
}

// Keep as a separate element so folks can easily override it with another icon
// or image file as needed.
.navbar-toggler-icon {
  display: inline-block;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
  width: 1.7em;
  height: 1.7em;

  @include media-breakpoint-up(sm) {
    width: 1.5em;
    height: 1.5em;
  }
}

// Generate series of `.navbar-expand-*` responsive classes for configuring
// where your navbar collapses.
.navbar-expand {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {

        >.container,
        >.container-fluid {}
      }

      @include media-breakpoint-up($next) {
        flex-flow: row nowrap;
        justify-content: flex-start;

        .navbar-nav {
          flex-direction: row;

          .dropdown-menu {
            position: absolute;
          }

          .dropdown-menu-right {
            right: 0;
            left: auto; // Reset the default from `.dropdown-menu`
          }

          .nav-link {
            padding-right: $spacer / 2;
            padding-left: $spacer / 2;

            @include media-breakpoint-up(lg) {
              padding-right: $spacer;
              padding-left: $spacer;
            }
          }
        }

        // For nesting containers, have to redeclare for alignment purposes
        >.container,
        >.container-fluid {
          flex-wrap: nowrap;
        }

        .navbar-collapse {
          display: flex !important; // stylelint-disable-line declaration-no-important

          // Changes flex-bases to auto because of an IE10 bug
          flex-basis: auto;
        }

        .navbar-toggler {
          display: none;
        }

        .dropup {
          .dropdown-menu {
            top: auto;
            bottom: 100%;
          }
        }
      }
    }
  }
}


// Navbar themes
//
// Styles for switching between navbars with light or dark background.

// Dark links against a light background
.navbar-light {
  .navbar-brand {
    color: $navbar-light-active-color;

    @include hover-focus {
      color: $navbar-light-active-color;
    }
  }

  .navbar-nav {
    .nav-link {
      color: $navbar-light-color;

      @include hover-focus {
        color: $navbar-light-hover-color;
      }

      &.disabled {
        color: $navbar-light-disabled-color;
      }
    }

    .show>.nav-link,
    .active>.nav-link,
    .nav-link.show,
    .nav-link.active {
      color: $navbar-light-active-color;
    }
  }

  .navbar-toggler {
    color: $navbar-light-color;
    // border-color: $navbar-light-toggler-border-color;
  }

  .collapsed .navbar-toggler-icon {
    background-image: $navbar-light-toggler-icon-bg;
  }

  .navbar-toggler-icon {
    background-image: $navbar-light-toggler-icon-bg-close;
    transition: opacity 0.25s ease-in-out;
  }

  .navbar-text {
    color: $navbar-light-color;

    a {
      color: $navbar-light-active-color;

      @include hover-focus {
        color: $navbar-light-active-color;
      }
    }
  }
}

// White links against a dark background
.navbar-dark {
  .navbar-brand {
    color: $navbar-dark-active-color;

    @include hover-focus {
      color: $navbar-dark-active-color;
    }
  }

  .navbar-nav {
    .nav-link {
      color: $navbar-dark-color;

      @include hover-focus {
        color: $navbar-dark-hover-color;
      }

      &.disabled {
        color: $navbar-dark-disabled-color;
      }
    }

    .show>.nav-link,
    .active>.nav-link,
    .nav-link.show,
    .nav-link.active {
      color: $navbar-dark-active-color;
    }
  }

  .navbar-toggler {
    color: $navbar-dark-color;
    border-color: $navbar-dark-toggler-border-color;
  }

  .navbar-toggler-icon {
    background-image: $navbar-dark-toggler-icon-bg;
  }

  .navbar-text {
    color: $navbar-dark-color;

    a {
      color: $navbar-dark-active-color;

      @include hover-focus {
        color: $navbar-dark-active-color;
      }
    }
  }
}
