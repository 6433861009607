/**
 * @license
 * MyFonts Webfont Build ID 3459189, 2017-10-02T14:36:42-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Futura-Bol by URW++
 * URL: https://www.myfonts.com/fonts/urw/futura/t-bold/
 * Copyright: Copyright 2010 by (URW)++ Design &amp; Development GmbH
 * Licensed pageviews: 10,000
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3459189
 * 
 * © 2017 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/34c875");

  
@font-face {
	font-family: 'Futura-Bold-Web';
	src: url('../fonts/34D689_0_0.eot');
	src: url('../fonts/34D689_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/34D689_0_0.woff2') format('woff2'),url('../fonts/34D689_0_0.woff') format('woff'),url('../fonts/34D689_0_0.ttf') format('truetype');
	font-style: normal;
	font-weight: bold;
  font-display: swap;
}
 