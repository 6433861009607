@import "common/variables";
@import "components/futura-bold";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
// endbower

// Bootstrap

@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";
@import "bootstrap/root";
@import "bootstrap/print";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
// @import "bootstrap/code";
@import "bootstrap/grid";
// @import "bootstrap/tables";
// @import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
// @import "bootstrap/dropdown";
// @import "bootstrap/button-group";
// @import "bootstrap/input-group";
// @import "bootstrap/custom-forms";
@import "bootstrap/nav";
@import "bootstrap/navbar";
// @import "bootstrap/card";
// @import "bootstrap/breadcrumb";
// @import "bootstrap/pagination";
// @import "bootstrap/badge";
// @import "bootstrap/jumbotron";
// @import "bootstrap/alert";
// @import "bootstrap/progress";
// @import "bootstrap/media";
// @import "bootstrap/list-group";
@import "bootstrap/close";
@import "bootstrap/modal";
// @import "bootstrap/tooltip";
// @import "bootstrap/popover";
// @import "bootstrap/carousel";
@import "bootstrap/utilities";

// Sage

@import "common/global";
// @import "components/buttons";
// @import "components/comments";
// @import "components/forms";
@import "components/grid";
@import "components/wp-classes";
@import "layouts/header";
// @import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";

// Custom

// @import "components/baguettebox";
@import "components/jquery.fancybox";


/*
 * Base structure
 */

html {
  touch-action: manipulation;
  font-size: 11px;
}

body {
  font-size: 13px;
  letter-spacing: 1px;
}

@include media-breakpoint-up(sm) {
  html {
    font-size: 14px;
  }

  body {
    font-size: 14px;
  }
}

@include media-breakpoint-up(md) {
  html {
    font-size: 15px;
  }
}

@include media-breakpoint-up(lg) {
  html {
    font-size: 16px;
  }
}

#bottom,
#left,
#right,
#top {
  position: fixed;
  background: $white;
  z-index: $zindex-fixed;
}

#left,
#right {
  top: 0;
  bottom: 0;
  width: $body-border-width-base;
}

#left,
#top,
#bottom {
  left: 0;
}

#right,
#top,
#bottom {
  right: 0;
}

#bottom,
#top {
  height: $body-border-width-base;
}

#top {
  top: 0;
}

#bottom {
  bottom: 0;
}

@include media-breakpoint-up(md) {

  #left,
  #right {
    width: $body-border-width-lg;
  }

  #bottom,
  #top {
    height: $body-border-width-lg;
  }
}

.main {
  margin: (-$navbar-height-base)+$body-border-width-base $body-border-width-base $body-border-width-base;

  @include media-breakpoint-up(md) {
    margin: (-$navbar-height-base)+$body-border-width-lg $body-border-width-lg $body-border-width-lg;
  }

  @include media-breakpoint-up(lg) {
    margin-top: -$navbar-height-lg+$body-border-width-lg;
  }

  @include media-breakpoint-up(xl) {
    margin-top: -$navbar-height-xl+$body-border-width-lg;
  }
}

.logo {
  width: $logo-width;
  height: $logo-height;

  @include media-breakpoint-down(xs) {
    transform: scale(1.125);
    transform-origin: left top;
  }
}

.logo svg {
  max-width: 100%;
}

.main {
  z-index: 4;
  position: relative;
}

.header-main {
  position: relative;
}

.header-photographer {
  z-index: 2;
  mix-blend-mode: hard-light;

  @include media-breakpoint-up(lg) {
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    pointer-events: none;
  }
}

.content-photographer {
  @include media-breakpoint-up(lg) {
    height: calc(100vh - (#{$body-border-width-lg} * 2));
    padding-top: (($navbar-padding-y / 2) + $body-border-width-lg * 2) * 2;
  }
}

.info-content,
.link-content-container {
  pointer-events: auto;
}

.info-content {
  @include media-breakpoint-up(md) {
    flex: 0;
  }
}

.info-content {
  @include media-breakpoint-up(md) {
    flex: 0 1 auto;
    overflow-y: auto;
    min-height: 0px;

    &::before,
    &::after {
      content: '';
      display: block;
      position: sticky;
      left: 0;
      width: 100%;
      height: $body-border-width-lg;

    }

    &::before {
      transition: opacity 0.1s ease-in-out;
      opacity: 0;
      top: 0;
      @include gradient-y($body-bg, rgba($body-bg, 0.25), 0%, 100%);
    }

    &::after {
      transition: opacity 0.15s ease-in-out;
      opacity: 0;
      bottom: 0;
      @include gradient-y(rgba($body-bg, 0.25), $body-bg, 0%, 100%);
    }
  }
}

.copy-content {
  &:last-child p {
    margin-bottom: 0;
  }
}

.link-content {
  margin-right: $spacer / 1.5;
  padding: ($spacer * 0.35) 0;
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: $link-text-shadow;
  font-size: $font-size-sm;

  @include media-breakpoint-up(md) {
    font-size: $font-size-xs;
  }

  &:last-child {
    margin-right: 0;
  }
}

.profile-content {
  @include media-breakpoint-up(xxl) {
    column-count: 2;
  }
}

.list-portfolio {
  z-index: 3;
  margin: 0;
  margin-bottom: 42vh;
  margin-top: (($navbar-padding-y / 2) + $body-border-width-lg * 2) * 2;

  @include media-breakpoint-up(lg) {
    margin-top: (($navbar-padding-y / 1.5) + $body-border-width-lg * 2) * 2;
  }

  @include media-breakpoint-up(xl) {
    margin-top: ($navbar-padding-y + $body-border-width-lg * 2) * 2;
  }

  @include hover-focus {
    > .list-portfolio-item {
      opacity: 0.2;

      @include hover-focus {
        opacity: 1;
      }
    }
  }
}

.heading-display {
  position: relative;
  font-family: $font-family-sans-serif-brand;
  line-height: $portfolio-list-line-height;
  text-transform: uppercase;
  flex: 0 0 auto;
  text-shadow: $headings-text-shadow;
}

.heading-display-subtitle {
  display: block;
  font-family: $font-family-sans-serif-brand;
  font-size: $h5-font-size;
  text-transform: uppercase;
  padding-bottom: $spacer * 1.5;

  span+span {
    &:before {
      content: "+ ";
      display: inline;
    }
  }
}

.link-display {
  color: $body-color;
  z-index: 2;
  display: block;
  text-shadow: none;

  // @include media-breakpoint-up(md) {
  //   display: inline-block;
  // }

  @include hover-focus {
    color: $body-color;
    text-decoration: none;
    z-index: 4;
    mix-blend-mode: hard-light;

    +.display-image {
      transition-delay: 0s;
      opacity: 1;
      z-index: 3;

      // @media (hover: none) {
      //   opacity: 0;
      // }
    }

    &.heading-display {
      text-shadow: $headings-text-shadow;
    }
  }

  &.over {
    color: $body-color;
    text-decoration: none;
    z-index: 4;
    mix-blend-mode: hard-light;

    +.display-image {
      transition-delay: 0s;
      opacity: 1;
      z-index: 3;
    }
  }
}

.link-display.toggle {
  z-index: 1;
  // opacity: 0.1;

  // @media (hover: hover) {
  //   opacity: 0.2;
  // }
}


.display-image {
  position: relative;
  z-index: 1;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;

  img {
    max-height: 78vh;
    padding-top: $body-border-width-base * 4;

    @media only screen and (orientation: landscape) {
      max-height: 68vh;
      padding-top: $body-border-width-base * 3;
    }

    @media only screen and (orientation: portrait) {
      max-height: 78vh;
    }

    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }

    @include media-breakpoint-up(lg) {
      padding-top: $body-border-width-lg * 2;
      padding-bottom: $body-border-width-base;
      max-width: 74vw;

      @media only screen and (orientation: portrait) {
        padding-top: $body-border-width-lg;
        padding-bottom: $body-border-width-base;
        max-height: 78vh;
      }

      @media only screen and (orientation: landscape) {
        max-height: 78vh;
      }
    }

    @include media-breakpoint-up(xl) {
      padding-bottom: $body-border-width-base;
      transform: translateY($spacer * 1.25);
    }
  }
}

.link-display-image {
  position: fixed;
  transition: all 0.25s 0.05s ease-in-out;
  pointer-events: none;
  opacity: 0;

  @include media-breakpoint-down(sm) {
    .container-fluid {
      padding: 0;
    }
  }

  @include media-breakpoint-up(md) {
    padding-left: $body-border-width-lg;
    padding-right: $body-border-width-lg;
  }

  img {
    padding-left: $body-border-width-base * 3;
    padding-right: $body-border-width-base * 3;
    padding-bottom: $body-border-width-base;
    opacity: 0.98;

    @include media-breakpoint-up(md) {
      padding-left: 0;
      padding-right: 0;
      opacity: 1;
    }
  }
}

.feature-image {
  padding-top: $body-border-width-base * 2;
  padding-bottom: $body-border-width-base * 2;

  @include media-breakpoint-up(lg) {
    min-height: 100vh;
    top: -$body-border-width-lg;
  }

  img {
    @include media-breakpoint-up(lg) {
      @media only screen and (orientation: portrait) {
        max-height: 100vw;
      }
    }
  }
}


// Masonry

.masonry {
  width: 100%;
  min-height: 0;
  min-width: 0;
  display: flex;
  flex-flow: column wrap;

  @include media-breakpoint-up(lg) {
    flex-flow: column wrap;
  }

  /* Force new columns */
  &::before,
  &::after {
    content: "";
    flex-basis: 100%;
    width: 0;
    order: 2;
  }
}

.masonry-panel {
  flex: none;

  @include media-breakpoint-up(sm) {
    &:nth-child(odd) {
      order: 1;
    }

    &:nth-child(even) {
      order: 2;
    }
  }

  @include media-breakpoint-up(lg) {
    &:nth-child(3n+1) {
      order: 1;
    }

    &:nth-child(3n+2) {
      order: 2;
    }

    &:nth-child(3n) {
      order: 3;
    }
  }
}

.masonry-panel-content {
  align-items: center;
  display: flex;
  justify-content: center;
}

.masonry-link {
  position: relative;
  outline: none;
  box-shadow: 0rem 1rem 1.7rem rgba($black, .025);
  margin-top: ($grid-gutter-width-base / 4);
  margin-bottom: ($grid-gutter-width-base / 4);

  @include media-breakpoint-up(sm) {
    margin-top: ($grid-gutter-width-base / 3);
    margin-bottom: ($grid-gutter-width-base / 3);
  }

  @include media-breakpoint-up(md) {
    margin-top: ($grid-gutter-width-base / 3);
    margin-bottom: ($grid-gutter-width-base / 3);
  }

  @include media-breakpoint-up(xl) {
    margin-top: ($grid-gutter-width-base / 2);
    margin-bottom: ($grid-gutter-width-base / 2);
  }

  @include hover {
    .masonry-overlay {
      opacity: 1;
    }
  }
}

.masonry-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: $grid-gutter-width-base / 2;
  padding-bottom: ($grid-gutter-width-base / 2) - $headings-margin-bottom;
  color: $white;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: right;
  background: rgba(0, 0, 0, 0.4);
  transition: all 0.15s ease-in-out;
  opacity: 0;
}
  
.masonry-title-small {
  font-size: calc(#{$font-size-xs} - 15%);
  line-height: $line-height-sm;
  font-weight: bold;
}

.masonry-title {
  font-family: $font-family-sans-serif-brand;
}

.masonry-link-hidden {
  width: 0;
  height: 0;
  display: none;
}

